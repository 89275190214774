@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

label.signin {
    @apply block text-sm font-medium leading-6 text-gray-900
}

input.signin {
    @apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
}

input.signinError {
    @apply block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 border-rose-500 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
}

a.signin {
    @apply font-semibold text-indigo-400 hover:text-indigo-500
}

button.signin {
    @apply flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500
}

button.signin-disabled {
    @apply flex w-full justify-center rounded-md bg-indigo-100 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500
}


h2.signin {
    @apply mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900
}